/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import NewComplaint from "../../../components/corporate/vigilance/Home/NewComplaint";

const VigilanceLayout = () => {
    const createComplaint: boolean = useSelector((state: any) => state.data.createComplaint);
    const location = useLocation();

    useEffect(() => {
        if (createComplaint) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [createComplaint]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {createComplaint && <NewComplaint />}
            <Outlet />
        </>
    );
};

export default VigilanceLayout;