import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseBtn } from "../svg/AllSvg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { uiActions } from "../../../shared/store/ui-Slice";

const LanguageChange = () => {
    const langOpen: boolean = useSelector((state: any) => state.data.langOpen);
    const selectLang: any = useSelector((state: any) => state.data.selectLang);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('common');

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setLangOpen(false))
        }
    };

    const handleChange = async () => {
        if (!!params?.lang || !!lang) {
            let url: any = await window.location.pathname?.replace(lang, selectLang?.value)
            navigate(url)
            dispatch(uiActions.changeLang(selectLang?.value));
            i18n.changeLanguage(selectLang?.value);
        } else {
            navigate(`/${selectLang?.value}` + window.location.pathname)
        }
        dispatch(dataActions.setLangOpen(false))
    };

    return (
        <>
            {langOpen &&
                <div className="fixed inset-0 z-[34] overflow-y-hidden top-0 ">
                    <div
                        className="fixed inset-0 w-full h-full bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]" onClick={() => dispatch(dataActions.setLangOpen(false))}
                    ></div>
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white rounded-[12px] w-[275px] ss:w-[320px] h-auto sm:w-[400px] relative p-[24px] shadow-[0px_20px_24px_-4px_rgba(16,24,40_ 0.08),0px_8px_8px_-4px_rgba(16,24,40,0.03)]">
                            <button className="absolute top-[8px] right-[8px] mm:top-[15px] mm:right-[15px]" onClick={() => dispatch(dataActions.setLangOpen(false))}>
                                <CloseBtn className="min-w-[23px]" />
                            </button>

                            <div className="flex flex-col gap-[15px] xlx:gap-[20px]">
                                <img src={require("../../../assets/images/lang.png")} alt="language" className="w-fit h-fit ml-[-5px]" />
                                <h4 className="text-primary font-bold">{lang === "en" ? `Change language to ${selectLang?.label}.` : lang === "hi" ? `भाषा को ${selectLang?.label} में बदलें.` : `भाषा ${selectLang?.label} बदला.`}</h4>
                                <h6 className="text-blackDull font-semibold">{lang === "en" ? `Change language to ${selectLang?.label} & access all the content of website in your preferable language.` : lang === "hi" ? `भाषा ${selectLang?.label} में बदलें और अपनी पसंदीदा भाषा में वेबसाइट की सभी सामग्री तक पहुँचें` : `भाषा ${selectLang?.label} मध्ये बदला आणि आपल्या पसंतीच्या भाषेत संकेतस्थळावरील सर्व मजकूर पाहा`}</h6>

                                <div className="flex items-center gap-[12px] mt-[15px] xlx:mt-[20px]">
                                    <button className="xlx:h-[44px] h-[40px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-base font-medium text-blackDull bg-white px-[18px] w-full border border-[#D0D5DD]" onClick={() => dispatch(dataActions.setLangOpen(false))}>{t("lang.go_back")}</button>
                                    <button className="xlx:h-[44px] h-[40px] rounded-[8px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-base font-medium text-white bg-blackDull px-[18px] w-full" onClick={handleChange} autoFocus={true}>{t("lang.change")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default LanguageChange;