/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../../shared/store/data-Slice";
import { CloseBtn, Delete, GroupClose, Warn } from "../../../common/svg/AllSvg";
import PrimaryInput from "../../../common/form/PrimaryInput";
import PrimaryTextarea from "../../../common/form/PrimaryTextarea";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import useAxios from "../../../../shared/hooks/use-axios";
import { url } from "../../../../shared/utils/url";
import { useTranslation } from "react-i18next";

const CreateAd = () => {
    const createAd: boolean = useSelector((state: any) => state.data.createAd);
    const editAd: any = useSelector((state: any) => state.data.editAd);
    const [files, setFiles] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [disable, setDisable] = useState<boolean>(false);
    const [err, setErr] = useState<any>("");
    const [submit, setSubmit] = useState<boolean>(false);
    const ref: any = useRef();
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!!editAd?.attach_pdf) {
            setUploadProgress(100)
            setFiles([{ name: `${editAd?.attach_pdf_details?.name}.${editAd?.attach_pdf_details?.type}`, url: editAd?.attach_pdf }])
        }
    }, [])

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: !!editAd?.title ? editAd?.title : '',
            last_date_submission: !!editAd?.last_date_submission ? editAd?.last_date_submission : '',
        },
        validationSchema: yup.object().shape({
            title: yup.string().required(t("recruitment.hr.create_ad.err.title")),
            last_date_submission: yup.date().required(t("recruitment.hr.create_ad.err.last_date")),
        }),
        onSubmit: async (values: any) => {
            //submit
            if (files?.length === 0) {
                setErr(t("recruitment.hr.create_ad.err.attach"))
                return;
            }

            setSubmit(true)
            try {
                const formData = new FormData();
                formData.append("title", values?.title);
                formData.append("last_date_submission", values?.last_date_submission);
                (uploading && files?.length > 0) && formData.append("attach_pdf", files[0]);
                const res: any = !!editAd ? await useAxios.patch(`${url?.createAd}${editAd?.id}/`, formData) : await useAxios.post(`${url?.createAd}`, formData)

                if (res && (res?.status === 201 || res?.status === 200)) {
                    setSubmit(false)
                    !!!editAd ? (!!params?.lang ? navigate(`/${params?.lang}/admin/ads`) : navigate(`/admin/ads`)) : dispatch(dataActions.setAdApiCall(true))
                    dispatch(dataActions.setCreateAd(false));
                    dispatch(dataActions.setEditAd(null))
                }
            } catch (error) {
                setSubmit(false)
                console.error("error while create or edit ad", error);
            }
        }
    });

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setCreateAd(false));
                dispatch(dataActions.setEditAd(null))
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setCreateAd(false))
            dispatch(dataActions.setEditAd(null))
        }
    };

    const simulateChunkUpload = (chunk: any) => {
        // console.log(chunk, "1MB")
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            setErr("")
            if (!acceptedFiles[0]?.name?.match(/\.(PDF|pdf)$/)) {
                setErr(t("passenger.lost_found.report_form.validation.valid_file"));
                return true;
            }

            if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
                setErr(t("passenger.lost_found.report_form.validation.size_err"));
                return;
            }
            setUploadProgress(0);
            setDisable(true)
            setFiles([acceptedFiles[0]])
            setUploading(true)

            let uploadFile = acceptedFiles[0];
            if (uploadFile) {
                const chunkSize = 1024 * 1024; // 1MB chunk size
                const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                let currentChunk = 0;

                const uploadNextChunk = () => {
                    const start = currentChunk * chunkSize;
                    const end = Math.min(start + chunkSize, uploadFile.size);
                    const chunk = uploadFile.slice(start, end);

                    simulateChunkUpload(chunk)
                        .then(() => {
                            currentChunk++;

                            if (currentChunk < totalChunks) {
                                const progress = Math.floor((currentChunk / totalChunks) * 100);
                                setUploadProgress(progress);

                                uploadNextChunk();
                            } else {
                                setUploadProgress(100);
                                setDisable(false)
                            }
                        })
                        .catch((error) => {
                            console.error('Error uploading chunk', error);
                        });
                };

                uploadNextChunk();
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }

        setFiles(arr)
    };

    const thumbs = files?.map((file: any, idx: number) => (
        <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] rounded-full bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
            <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

            {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
                <div className="flex items-center gap-[10px]">
                    <h1 className="text-blackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress}%</h1>
                    <button aria-label="Delete" title="Delete" className="cursor-pointer" type="button" onClick={() => removeFile(idx)}>
                        <GroupClose />
                    </button>
                </div> :
                <div className="flex items-center gap-[10px] whitespace-nowrap">
                    <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
                        <input {...getInputProps()} />
                        <button onClick={open} type="button" className="text-blackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable}> {t("passenger.lost_found.report_form.form.replace")}</button>
                    </div>
                    <div className="border-r h-[16px] border-primary"></div>
                    <button aria-label="Delete" title="Delete" onClick={() => removeFile(idx)} type="button">
                        <Delete />
                    </button>
                </div>
            }
        </div>
    ));

    return (
        <>
            {createAd &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(171,171,171,0.70)] backdrop-blur-[10px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="relative m-[15px] mm:mx-auto bg-white rounded-[15px] w-full xsm:w-[480px] h-full lg:min-h-[457px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12)" ref={ref}>
                            <div className="flex flex-col relative w-full h-full items-start justify-center p-[20px] xlx:p-[24px] z-[1]">
                                <div className="flex w-full justify-between">
                                    <div className="flex flex-col w-full xlx:gap-[4px]">
                                        <h3 className="font-bold text-primaryText">{!!editAd ? t("recruitment.hr.create_ad.edit_title") : t("recruitment.hr.create_ad.title")}</h3>
                                        <h6 className="text-blackDark font-medium">{!!editAd ? t("recruitment.hr.create_ad.edit_desc") : t("recruitment.hr.create_ad.desc")}</h6>
                                    </div>
                                    <button className="w-[23px] h-[23px] rounded-full" type="button" onClick={() => { dispatch(dataActions.setCreateAd(false)); dispatch(dataActions.setEditAd(null)); }}>
                                        <CloseBtn />
                                    </button>
                                </div>

                                <div className="w-full flex flex-col gap-[15px] mt-[20px] xlx:mt-[29px]">
                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryTextarea title={`${t("recruitment.hr.create_ad.field.title")}*`} placeholder={t("recruitment.hr.create_ad.field.title")} rows="4" name="title" value={formik?.values?.title} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.title && formik?.errors?.title && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{formik?.errors?.title}</span>
                                        </div>}
                                    </div>

                                    <div className="grid grid-cols-1 xsm:grid-cols-2 gap-[15px]">
                                        <PrimaryInput title={t("recruitment.hr.create_ad.field.created_date")} type="date" name="created_date" defaultValue={!!editAd?.created_at ? new Date(editAd?.created_at).toLocaleDateString('en-CA') : new Date().toLocaleDateString('en-CA')} disabled={true} placeholder={t("recruitment.hr.create_ad.field.created_date")} />

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={t("recruitment.hr.create_ad.field.last_date")} type="date" name="last_date_submission" placeholder={t("recruitment.hr.create_ad.field.last_date")} value={formik?.values?.last_date_submission} onBlur={formik?.handleBlur} onChange={formik?.handleChange} min={new Date().toISOString().split("T")[0]} />
                                            {formik?.touched?.last_date_submission && formik?.errors?.last_date_submission && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.last_date_submission}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[5px] xlx:gap-[10px]">
                                        <h6 className="text-primaryText font-bold whitespace-nowrap">{t("recruitment.hr.create_ad.field.attach")} </h6>

                                        {files?.length === 0 && <div className="flex flex-col gap-[4px]">
                                            <div className="h-[35px] xlx:h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[18px] xlx:pr-[22px]">
                                                <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
                                                    <input {...getInputProps()} />
                                                    <button type="button" onClick={open} className="h-[25px] xlx:h-[30px] flex items-center justify-center w-fit px-[15px] xlx:px-[19px] text-white font-bold text-sm !leading-tight rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable || files?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
                                                </div>

                                                <h6 className="text-blackDull font-normal !leading-tight">PDF   <span className="text-primaryText font-semibold !leading-tight"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
                                            </div>
                                            {!!err && <div className="flex gap-[6px] mt-[4px]"><Warn />
                                                <span className="text-danger text-xs font-medium">{err}</span>
                                            </div>}
                                        </div>}
                                    </div>

                                    {files?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs}</aside>}

                                    <div className="mt-[30px] xlx:mt-[40px]">
                                        <SecondaryBtn title={t("recruitment.hr.create_ad.field.save")} css="w-full uppercase font-bold" disabled={submit || disable} onClick={formik.handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CreateAd;